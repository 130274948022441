import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import HearticleExplorer from './HearticleExplorer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import debounce from 'lodash/debounce';
import { PubMedArticle, PubMedSearchSuggestion } from '../types';
import PubMedSearchCard from './PubMedSearchCard';

const DualExplorer = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('hearticles');

  return (
    <div className="w-full relative z-10">
      {/* Simple tab navigation */}
      <div className="w-full max-w-5xl mx-auto">
        <div className="flex w-full max-w-md mb-6 bg-white/50 backdrop-blur-sm rounded-lg">
          <button
            onClick={() => setActiveTab('hearticles')}
            className={`w-1/2 py-3 text-lg transition-colors rounded-l-lg ${
              activeTab === 'hearticles'
                ? 'text-[#49ABDB] bg-white/80'
                : 'text-gray-600 hover:bg-white/30'
            }`}
          >
            Hearticles
          </button>
          <button
            onClick={() => setActiveTab('pubmed')}
            className={`w-1/2 py-3 text-lg transition-colors rounded-r-lg ${
              activeTab === 'pubmed'
                ? 'text-[#49ABDB] bg-white/80'
                : 'text-gray-600 hover:bg-white/30'
            }`}
          >
            PubMed
          </button>
        </div>

        {/* Content Area */}
        <div>
          {activeTab === 'hearticles' ? (
            <HearticleExplorer />
          ) : (
            <PubMedExplorer />
          )}
        </div>
      </div>
    </div>
  );
};

const PubMedExplorer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchResults, setSearchResults] = useState<PubMedArticle[]>([]);
  const [suggestions, setSuggestions] = useState<PubMedSearchSuggestion[]>([]);
    const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasPerformedSearch, setHasPerformedSearch] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

  const debouncedFetchSuggestions = debounce(async (term: string) => {
    if (term.length >= 3) {
      try {
        const response = await axios.get('/api/pubmedarticles/search/autocomplete', {
          params: { searchTerm: term, languageCode: currentLanguage }
        });
        setSuggestions(response.data);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, 300);

  const performSearch = async (term: string, currentPage: number = 0) => {
    setLoading(true);
    setHasPerformedSearch(true);
    try {
      const response = await axios.get('/api/pubmedarticles/search', {
        params: {
          searchTerm: term,
          languageCode: currentLanguage,
          page: currentPage,
          size: 8
        }
      });
      setSearchResults(response.data.content);
      setTotalPages(response.data.totalPages);
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error performing search:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = (term: string = searchTerm) => {
    if (term.length >= 3) {
      performSearch(term);
      setPage(0);
    }
  };

  const handleSuggestionClick = (article: any) => {
    navigate(`/pubmed/${article.pmid}`);
  };

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchTerm]);

  return (
    <div className="w-full">
      {/* Search input */}
      <div className="relative max-w-5xl mx-auto mb-8 text-black">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={t('search.pubmed.placeholder', 'Search medical literature...')}
          className="w-full p-4 pl-12 rounded-lg border-2 border-[#49ABDB] focus:outline-none focus:ring-2 focus:ring-[#49ABDB] focus:border-transparent text-lg"
        />
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#49ABDB]" />
        
        {/* Suggestions dropdown */}
        {showSuggestions && suggestions.length > 0 && (
          <div className="absolute w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-72 overflow-y-auto z-50">
            <div className="p-3 bg-gray-50 border-b border-gray-200 flex items-center justify-between">
              <span className="font-medium text-gray-600">{t('search.pubmed.quickResults')}</span>
              <span className="text-sm text-gray-500">{suggestions.length} {t('search.found')}</span>
            </div>
            {suggestions.map((article) => (
              <button
                key={article.pmid}
                onClick={() => handleSuggestionClick(article)}
                className="w-full p-4 text-left hover:bg-gray-50 border-b border-gray-100"
              >
                <div className="font-bold text-[#49ABDB]">{article.title}</div>
                <div className="text-sm text-gray-600">
                  {article.authors.slice(0, 3).join(', ')}
                  {article.authors.length > 3 && ' et al.'}
                </div>
                <div className="text-xs text-gray-500 mt-1">
                  {article.journal} • {new Date(article.publicationDate).getFullYear()}
                </div>
              </button>
            ))}
          </div>
        )}

        {/* Loading state */}
        {loading && (
          <div className="text-center py-8 text-[#49ABDB]">
            {t('loading')}
          </div>
        )}

        {/* No results */}
        {!loading && searchResults.length === 0 && hasPerformedSearch && (
          <div className="text-center py-8 text-[#49ABDB]">
            {t('search.pubmed.noResults')}
          </div>
        )}

        {/* Search results */}
        {!loading && searchResults.length > 0 && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mt-8">
              {searchResults.map((article) => (
                <PubMedSearchCard
                  key={article.pmid}
                  article={article}
                  currentLanguage={currentLanguage}
                />
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center items-center gap-4 mt-6 p-4">
              <button
                onClick={() => {
                  setPage(prev => {
                    const newPage = Math.max(0, prev - 1);
                    performSearch(searchTerm, newPage);
                    return newPage;
                  });
                }}
                disabled={page === 0}
                className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
              >
                {t('pagination.previous')}
              </button>
              <span className="text-[#111D4E]">
                {t('pagination.pageOf', { current: page + 1, total: totalPages })}
              </span>
              <button
                onClick={() => {
                  setPage(prev => {
                    const newPage = Math.min(totalPages - 1, prev + 1);
                    performSearch(searchTerm, newPage);
                    return newPage;
                  });
                }}
                disabled={page === totalPages - 1}
                className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
              >
                {t('pagination.next')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};



export default DualExplorer;