import { PubMedArticle } from "../types";
import AdaptiveTitle from "./AdaptiveTitle";
import { PubMedCard } from "./PubMedCard";

interface FeaturedLayoutProps {
    featuredArticle: PubMedArticle;
    sideArticles: PubMedArticle[];
    gridArticles: PubMedArticle[];
    onRubricClick: (rubricId: number) => void;
    onReadStudy: (pmid: string) => void;
    getTranslation: (article: PubMedArticle) => any;
    getImageUrl: (name: string | undefined, size?: 'big' | 'small') => string;
    isRecent: (article: PubMedArticle) => boolean;
    t: (key: string) => string;
}

export const FeaturedLayout: React.FC<FeaturedLayoutProps> = ({
    featuredArticle,
    sideArticles,
    gridArticles,
    onRubricClick,
    onReadStudy,
    getTranslation,
    getImageUrl,
    isRecent,
    t
}) => {

    // Helper function to safely get rubric name
    const safeRubricName = (article: PubMedArticle) => {
        return article.topic?.rubric?.name || '';
    };

    // Helper function to safely get rubric id and handle click
    const handleRubricClick = (article: PubMedArticle) => {
        const rubricId = article.topic?.rubric?.id;
        if (rubricId) {
            onRubricClick(rubricId);
        }
    };

    // Helper function to safely get translated title with fallback to original title
    const safeTitle = (article: PubMedArticle) => {
        const translation = getTranslation(article);
        return translation?.translatedTitle || article.title || '';
    };

    // Helper function to safely get keywords with fallback to original keywords
    const safeKeywords = (article: PubMedArticle) => {
        const translation = getTranslation(article);
        const translatedKeywords = translation?.keywords;
        const originalKeywords = article.keywords;
        
        const keywordsToUse = translatedKeywords || originalKeywords;
        return Array.isArray(keywordsToUse) && keywordsToUse.length > 0
            ? keywordsToUse.join(' • ')
            : '';
    };

    return (
        <>
            {/* Featured and Side Articles Layout */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-[20px] mb-4 mt-16 xl:mt-48">
                {/* Featured Article */}
                <div className="xl:col-span-2 bg-[#cde1ee] p-4 xl:p-6 relative overflow-visible min-h-[400px] xl:h-[600px] flex flex-col">
                    <div className="absolute left-1/2 transform -translate-x-1/2 -top-16 xl:-top-48">
                        <img
                            src={getImageUrl(safeRubricName(featuredArticle))}
                            alt={safeRubricName(featuredArticle)}
                            className="w-32 h-32 xl:w-96 xl:h-96 object-contain"
                        />
                    </div>

                    <div className="mt-16 xl:mt-48 flex justify-between flex-1">
                        <div className="w-full xl:w-2/3">
                            <button 
                                onClick={() => handleRubricClick(featuredArticle)}
                                className="px-2 py-1 rounded text-sm xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                            >
                                {safeRubricName(featuredArticle)}
                            </button>
                            <h2 className="text-lg xl:text-2xl font-bold mb-2 text-[#111D4E] mt-4">
                                {t('pubmed.featuredStudy')}
                            </h2>
                            <AdaptiveTitle 
                                text={safeTitle(featuredArticle)}
                                baseClassName="h-32"
                                maxFontSize={24}
                                minFontSize={16}
                            />
                            <p className="text-sm xl:text-lg text-[#111D4E] mt-4 italic">
                                {safeKeywords(featuredArticle)}
                            </p>

                            <button
                                onClick={() => onReadStudy(featuredArticle.pmid)}
                                className="inline-flex items-center justify-center h-12 px-6 py-2 mt-6 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                            >
                                {t('pubmed.readOriginal')}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Side Articles */}
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-[20px]">
                    {sideArticles.map((article) => (
                        <div key={article.pmid} className="bg-[#cde1ee] p-4 relative min-h-[200px] xl:h-[290px] flex flex-col overflow-hidden">
                            {(article.citationCount === 0 || article.citationCount == null) && isRecent(article) && (
                                <div className="absolute top-0 right-0 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white text-xs font-bold py-1 px-6 transform origin-top-right rotate-45 translate-y-8 translate-x-4">
                                    {t('pubmed.new')}
                                </div>
                            )}
                            <div className="flex flex-1">
                                <div className="flex-1">
                                    <button 
                                        onClick={() => handleRubricClick(article)}
                                        className="px-2 py-1 rounded text-xs xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                                    >
                                        {safeRubricName(article)}
                                    </button>
                                    <AdaptiveTitle 
                                        text={safeTitle(article)}
                                        baseClassName="h-24 mt-2"
                                        maxFontSize={20}
                                        minFontSize={14}
                                    />
                                    <p className="text-xs xl:text-base text-[#111D4E] mt-2 line-clamp-2">
                                        {safeKeywords(article)}
                                    </p>
                                </div>
                                <img
                                    src={getImageUrl(safeRubricName(article), 'small')}
                                    alt={safeRubricName(article)}
                                    className="w-16 h-16 xl:w-32 xl:h-32 object-contain ml-4"
                                />
                            </div>
                            <button
                                onClick={() => onReadStudy(article.pmid)}
                                className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                            >
                                {t('pubmed.readOriginal')}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {/* Grid Articles */}
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {gridArticles.map((article) => (
                    <PubMedCard
                        key={article.pmid}
                        article={article}
                        onRubricClick={onRubricClick}
                        onReadStudy={onReadStudy}
                        getTranslation={getTranslation}
                        getImageUrl={getImageUrl}
                        isRecent={isRecent}
                        t={t}
                    />
                ))}
            </div>
        </>
    );
};