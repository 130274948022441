import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import clouds from '../assets/images/clouds.png';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { login } from '../store/authSlice'; // Import your login thunk
// ^ This is the same action you use in Login.tsx

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email,    setEmail]    = useState('');
  const [password, setPassword] = useState('');
  const [error,    setError]    = useState('');
  const [loading,  setLoading]  = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // Calculate your background styling (unchanged)
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Basic validation
    if (password.length < 6 || password.length > 40) {
      setError('Password must be between 6 and 40 characters');
      setLoading(false);
      return;
    }
    if (username.length < 3 || username.length > 40) {
      setError('Username must be between 3 and 40 characters');
      setLoading(false);
      return;
    }
    if (email.length > 50) {
      setError('Email must be less than 50 characters');
      setLoading(false);
      return;
    }

    try {
      // 1) Sign up the user
      await axios.post('/api/auth/signup', {
        username,
        email,
        password,
        role: ['ROLE_USER'],
      });

      // 2) Immediately log in using your existing Redux login thunk
      //    (the same one used in Login.tsx)
      await dispatch(login({ username, password })).unwrap();

      // 3) Navigate to post-signup page (or wherever you want)
      navigate('/post-signup');

    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || 'Signup failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={backgroundStyle} className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div style={cloudImageStyle}></div>
      <div style={overlayStyle}></div>

      <div className="max-w-md w-full space-y-8 relative z-10">
        <div>
          <h2 className="mt-6 text-center text-4xl font-bold text-[#49ABDB]">
            Join Our Community
          </h2>
          <p className="mt-2 text-center text-base text-[#111D4E]">
            Create your account to access personalized health essentials
          </p>
        </div>
        <div className="bg-[#CAD4DD] p-8 rounded-lg shadow-lg">
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" value="true" />
            <div className="space-y-4">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-[#111D4E]">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 
                             rounded-md placeholder-gray-500 text-gray-900 focus:outline-none 
                             focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                  placeholder="Choose a username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-[#111D4E]">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 
                             rounded-md placeholder-gray-500 text-gray-900 focus:outline-none 
                             focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-[#111D4E]">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 
                             rounded-md placeholder-gray-500 text-gray-900 focus:outline-none 
                             focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                  placeholder="Create a password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm text-center">{error}</div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center px-4 py-2 text-base font-bold rounded 
                         text-white bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] 
                         hover:opacity-90 transition-opacity disabled:opacity-50"
            >
              {loading ? 'Creating Account...' : 'Create Account'}
            </button>
          </form>
        </div>
        <p className="mt-2 text-center text-sm text-[#111D4E]">
          Already have an account?{' '}
          <button
            onClick={() => navigate('/login')}
            className="font-medium text-[#49ABDB] hover:opacity-80 transition-opacity"
          >
            Sign in
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signup;
