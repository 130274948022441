import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../axios';
import { RootState } from '../store';
import StatusModal from './StatusModal';

interface Rubric {
  id: number;
  name: string;
  description: string;
}

const RubricSelection: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [rubrics, setRubrics] = useState<Rubric[]>([]);
  const [selectedRubrics, setSelectedRubrics] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Check if user has admin or paid access
  const hasAccess = user?.roles?.includes('PERMISSION_ADMIN') || 
                   user?.roles?.includes('PERMISSION_PAID_ACCESS');

  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    type: 'success' | 'error';
  }>({
    isOpen: false,
    type: 'success'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch all available rubrics
        const rubricsRes = await axios.get('/api/rubrics');
        setRubrics(rubricsRes.data);

        // Fetch user's selected rubrics if user exists
        if (user?.id) {
          const selectedRes = await axios.get(`/api/admin/users/${user.id}/selected-rubrics`);
          const selectedIds = selectedRes.data.map((rubric: Rubric) => rubric.id);
          setSelectedRubrics(selectedIds);
        }
      } catch (err) {
        setError('Could not load rubrics');
        console.error('Error fetching rubrics:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.id]); // Only re-run if user ID changes

  const handleToggleRubric = (rubricId: number) => {
    if (selectedRubrics.includes(rubricId)) {
      setSelectedRubrics(selectedRubrics.filter(id => id !== rubricId));
    } else {
      setSelectedRubrics([...selectedRubrics, rubricId]);
    }
  };

 const handleSave = async () => {
    if (!user) return;
    try {
      await axios.put(`/api/admin/users/${user.id}/rubrics`, selectedRubrics);
      setModalState({
        isOpen: true,
        type: 'success'
      });
    } catch (err) {
      console.error(err);
      setModalState({
        isOpen: true,
        type: 'error'
      });
    }
  };

  return (
    <div className="space-y-4 text-black">
      <h2 className="text-xl font-bold">Select Your Rubrics</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {rubrics.map(rubric => (
          <label
            key={rubric.id}
            className={`flex items-center space-x-2 p-2 rounded border ${
              hasAccess ? 'cursor-pointer hover:bg-gray-50' : 'cursor-not-allowed bg-gray-200'
            }`}
          >
            <input
              type="checkbox"
              checked={selectedRubrics.includes(rubric.id)}
              onChange={() => handleToggleRubric(rubric.id)}
              disabled={!hasAccess}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2">{rubric.name}</span>
          </label>
        ))}
      </div>
      <button
        onClick={handleSave}
        disabled={!hasAccess}
        className={`px-4 py-2 rounded ${
          hasAccess
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-gray-300 text-gray-500'
        }`}
      >
        Save
      </button>
      {!hasAccess && (
        <p className="text-gray-500 text-sm">
          You need a paid subscription or admin access to manage rubrics.
        </p>
      )}
      <StatusModal 
        isOpen={modalState.isOpen}
        onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
        type={modalState.type}
      />
    </div>
  );
};

export default RubricSelection;