import { t } from "i18next";

const PaginationControls = ({ 
    currentPage, 
    totalPages, 
    onPageChange 
}: { 
    currentPage: number; 
    totalPages: number; 
    onPageChange: (page: number) => void; 
}) => {
    // Function to determine which page numbers to show
    const getPageNumbers = () => {
        const delta = 2; // Number of pages to show on each side of current page
        const range: (number | string)[] = [];
        
        // Always add first page
        range.push(0);
        
        // Calculate start and end of the range around current page
        let start = Math.max(1, currentPage - delta);
        let end = Math.min(totalPages - 2, currentPage + delta);
        
        // Add ellipsis after first page if needed
        if (start > 1) {
            range.push('...');
        }
        
        // Add pages around current page
        for (let i = start; i <= end; i++) {
            range.push(i);
        }
        
        // Add ellipsis before last page if needed
        if (end < totalPages - 2) {
            range.push('...');
        }
        
        // Always add last page if there is more than one page
        if (totalPages > 1) {
            range.push(totalPages - 1);
        }
        
        return range;
    };

    // Only show pagination if there's more than one page
    if (totalPages <= 1) return null;

    return (
        <div className="flex justify-center items-center gap-2 mt-8 flex-wrap">
            <button 
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 0}
                className="px-4 py-2 text-[#49ABDB] disabled:text-gray-400 font-bold hover:bg-[#cde1ee] rounded-md transition-colors disabled:hover:bg-transparent"
            >
                {t('pubmed.previous')}
            </button>
            
            {getPageNumbers().map((pageNum, index) => {
                if (pageNum === '...') {
                    return (
                        <span 
                            key={`ellipsis-${index}`} 
                            className="px-2 text-[#49ABDB]"
                        >
                            •••
                        </span>
                    );
                }
                
                return (
                    <button
                        key={`page-${pageNum}`}
                        onClick={() => onPageChange(pageNum as number)}
                        className={`w-10 h-10 rounded-md ${
                            currentPage === pageNum 
                                ? 'bg-[#49ABDB] text-white' 
                                : 'text-[#49ABDB] hover:bg-[#cde1ee]'
                        } font-bold transition-colors`}
                    >
                        {(pageNum as number) + 1}
                    </button>
                );
            })}
            
            <button 
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages - 1}
                className="px-4 py-2 text-[#49ABDB] disabled:text-gray-400 font-bold hover:bg-[#cde1ee] rounded-md transition-colors disabled:hover:bg-transparent"
            >
                {t('pubmed.next')}
            </button>
        </div>
    );
};

export default PaginationControls;