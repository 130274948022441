import React, { useEffect, useRef, useState } from 'react';

interface AdaptiveTitleProps {
  text: string;
  baseClassName?: string;
  minFontSize?: number;
  maxFontSize?: number;
}

const AdaptiveTitle: React.FC<AdaptiveTitleProps> = ({ 
  text, 
  baseClassName = '', 
  minFontSize = 12, 
  maxFontSize = 24 
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const adjustFontSize = () => {
      if (!containerRef.current || !textRef.current) return;

      const container = containerRef.current;
      const textElement = textRef.current;
      let currentSize = maxFontSize;

      // Reset font size to max to get proper measurements
      textElement.style.fontSize = `${currentSize}px`;
      
      // Check if text overflows container height
      while (
        currentSize > minFontSize && 
        (textElement.scrollHeight > container.clientHeight ||
         textElement.scrollWidth > container.clientWidth)
      ) {
        currentSize -= 0.5;
        textElement.style.fontSize = `${currentSize}px`;
      }

      setFontSize(currentSize);
    };

    adjustFontSize();

    // Add resize listener
    const resizeObserver = new ResizeObserver(adjustFontSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [text, maxFontSize, minFontSize]);

  return (
    <div ref={containerRef} className={`relative ${baseClassName}`}>
      <div 
        ref={textRef}
        style={{ fontSize: `${fontSize}px` }}
        className="font-bold text-[#49ABDB]"
      >
        {text}
      </div>
    </div>
  );
};

export default AdaptiveTitle;
