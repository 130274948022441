import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../axios';
import {
  PubMedArticle,
  PubMedArticleTranslation,
  AustrianPubMedArticle,
  AustrianPubMedSearchResponse,
  Rubric,
} from '../types';
import { RootState } from '../store';
import PaginationControls from './PaginationControls';
import UpgradeModal from './UpgradeModal';
import AdaptiveTitle from './AdaptiveTitle';
import { ArticleGrid } from './ArticleGrid';
import { FeaturedLayout } from './FeaturedLayout';

interface RubricArticles {
  rubricId: number;
  rubricName: string;
  articles: PubMedArticle[];
  currentPage: number;
  totalPages: number;
}

const SERVER_SIZE_PER_RUBRIC = 4;

const PubMedDisplay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  // Redux state
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);

  // Access control
  const hasFullAccess = user?.roles?.includes('PERMISSION_ADMIN') || 
    user?.roles?.includes('PERMISSION_PAID_ACCESS');

  // User rubrics state
  const [userRubrics, setUserRubrics] = useState<Rubric[]>([]);

  // Featured layout state (fallback if no rubrics)
  const [featuredArticle, setFeaturedArticle] = useState<PubMedArticle | null>(null);
  const [sideArticles, setSideArticles] = useState<PubMedArticle[]>([]);
  const [gridArticles, setGridArticles] = useState<PubMedArticle[]>([]);

  // Main content state with pagination
  const [latestRubricsData, setLatestRubricsData] = useState<RubricArticles[]>([]);
  const [topCitedRubricsData, setTopCitedRubricsData] = useState<RubricArticles[]>([]);

  // Austrian articles state
  const [austrianArticles, setAustrianArticles] = useState<AustrianPubMedArticle[]>([]);
  const [austrianLoading, setAustrianLoading] = useState(true);
  const [austrianPage, setAustrianPage] = useState(0);
  const [austrianTotalPages, setAustrianTotalPages] = useState(0);
  const AUST_PAGE_SIZE = 4;

  // UI state
  const [loading, setLoading] = useState(true);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  // URL params
  const selectedRubricId = useMemo(() => {
    const val = searchParams.get('rubric');
    return val ? Number(val) : null;
  }, [searchParams]);

  // Fetch user rubrics on mount
  useEffect(() => {
    if (!user?.id) return;
    axios
      .get(`/api/admin/users/${user.id}/selected-rubrics`)
      .then((resp) => setUserRubrics(resp.data))
      .catch((err) => console.error('Error fetching user rubrics:', err));
  }, [user?.id]);

  // Helper function to fetch articles for a specific rubric
  const fetchArticlesForRubric = async (
    rubricId: number,
    rubricName: string,
    endpoint: 'latest' | 'top-cited',
    page: number
  ) => {
    const url = `/api/pubmedarticles/rubric/${rubricId}/${endpoint}`;
    const resp = await axios.get(url, {
      params: { page, size: SERVER_SIZE_PER_RUBRIC },
    });

    let articles: PubMedArticle[] = [];
    let totalPages = 1;

    if (resp.data?.content) {
      articles = resp.data.content;
      totalPages = resp.data.totalPages;
    } else if (Array.isArray(resp.data)) {
      articles = resp.data;
      totalPages = Math.ceil(resp.data.length / SERVER_SIZE_PER_RUBRIC);
    }
    console.log("articles: ", articles)


    return { articles, totalPages };
  };

  // Main fetch function for latest and top-cited articles
  const fetchArticles = useCallback(async () => {
    setLoading(true);
    try {
      let rubricIds: number[] = [];

      if (selectedRubricId) {
        rubricIds = [selectedRubricId];
      } else if (userRubrics.length > 0) {
        rubricIds = userRubrics.map(r => r.id);
      }

      // If no rubrics, show featured layout
      if (rubricIds.length === 0) {
        const resp = await axios.get<PubMedArticle[]>('/api/pubmedarticles/top-by-rubric/today');
        const articles = resp.data || [];
        if (articles.length > 0) {
          const sorted = [...articles].sort(
            (a, b) => (b.citationCount || 0) - (a.citationCount || 0)
          );
          console.log("articles: ", articles)

          setFeaturedArticle(sorted[0]);
          setSideArticles(sorted.slice(1, 3));
          setGridArticles(sorted.slice(3));
        } else {
          setFeaturedArticle(null);
          setSideArticles([]);
          setGridArticles([]);
        }
        setLatestRubricsData([]);
        setTopCitedRubricsData([]);
        return;
      }

      const newLatestData: RubricArticles[] = [];
      const newTopData: RubricArticles[] = [];

      // Fetch data for each rubric
      for (const rubId of rubricIds) {
        const foundRubric = userRubrics.find(r => r.id === rubId);
        const name = foundRubric ? foundRubric.name : `Rubric #${rubId}`;

        // Fetch latest articles
        const { articles: latestArticles, totalPages: latestPages } = 
          await fetchArticlesForRubric(rubId, name, 'latest', 0);
        
        // Fetch top-cited articles
        const { articles: topArticles, totalPages: topPages } = 
          await fetchArticlesForRubric(rubId, name, 'top-cited', 0);

        newLatestData.push({
          rubricId: rubId,
          rubricName: name,
          articles: latestArticles,
          currentPage: 0,
          totalPages: latestPages
        });

        newTopData.push({
          rubricId: rubId,
          rubricName: name,
          articles: topArticles,
          currentPage: 0,
          totalPages: topPages
        });
      }

      // Clear featured layout when showing rubrics
      setFeaturedArticle(null);
      setSideArticles([]);
      setGridArticles([]);

      setLatestRubricsData(newLatestData);
      setTopCitedRubricsData(newTopData);

    } catch (err) {
      console.error('Error fetching PubMed articles:', err);
    } finally {
      setLoading(false);
    }
  }, [selectedRubricId, userRubrics]);

  // Handle pagination for both latest and top-cited sections
  const handlePageChange = async (
    rubricId: number,
    newPage: number,
    type: 'latest' | 'top-cited'
  ) => {
    const dataArray = type === 'latest' ? latestRubricsData : topCitedRubricsData;
    const setDataArray = type === 'latest' ? setLatestRubricsData : setTopCitedRubricsData;
    
    const rubricIndex = dataArray.findIndex(r => r.rubricId === rubricId);
    if (rubricIndex === -1) return;

    const rubric = dataArray[rubricIndex];
    const { articles, totalPages } = await fetchArticlesForRubric(
      rubricId,
      rubric.rubricName,
      type,
      newPage
    );

    const newData = [...dataArray];
    newData[rubricIndex] = {
      ...rubric,
      articles,
      currentPage: newPage,
      totalPages
    };

    setDataArray(newData);
  };

  // Fetch Austrian articles
  const fetchAustrianArticles = useCallback(async () => {
    setAustrianLoading(true);
    try {
      const resp = await axios.get<AustrianPubMedSearchResponse>('/api/austrian-articles', {
        params: {
          page: austrianPage,
          size: AUST_PAGE_SIZE,
        },
      });
      if (resp.data?.content) {
        setAustrianArticles(resp.data.content);
        console.log("austrian: ", resp.data.content)
        setAustrianTotalPages(resp.data.totalPages);
      }
    } catch (error) {
      console.error('Error fetching Austrian articles:', error);
    } finally {
      setAustrianLoading(false);
    }
  }, [austrianPage]);

  // Initial data fetch
  useEffect(() => {
    fetchArticles();
    fetchAustrianArticles();
  }, [fetchArticles, fetchAustrianArticles]);

  // Event handlers
  const handleUpgradeModalClose = () => setIsUpgradeModalOpen(false);

  const handleRubricClick = useCallback(
    (rubricId: number | undefined) => {
      if (!rubricId) return;
      if (hasFullAccess) {
        const newRubricId = rubricId === selectedRubricId ? null : rubricId;
        if (newRubricId) {
          setSearchParams({ rubric: newRubricId.toString() }, { replace: true });
        } else {
          setSearchParams({}, { replace: true });
        }
      } else {
        setIsUpgradeModalOpen(true);
      }
    },
    [hasFullAccess, selectedRubricId, setSearchParams]
  );

  const handleReadFullStudy = useCallback(
    (pmid: string) => {
      navigate(`/pubmed/${pmid}`);
    },
    [navigate]
  );

  const handleAustrianPageChange = (newPage: number) => setAustrianPage(newPage);

  // Helper functions
  const getTranslation = useCallback(
    (article: PubMedArticle | AustrianPubMedArticle): PubMedArticleTranslation | null => {
      if (!article.translations?.length) return null;
      return article.translations.find(t => t.languageCode === currentLanguage) || null;
    },
    [currentLanguage]
  );

  const getImageUrl = (name: string | undefined, size: 'big' | 'small' = 'big') => {
    if (!name) return '';
    let imageName = name
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss') + '.png';
    if (size === 'big') {
      imageName = 'big/' + imageName;
    }
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  const isRecent = (article: PubMedArticle) =>
    new Date(article.publicationDate).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000;

  const isRecentAustrian = (article: AustrianPubMedArticle) =>
    new Date(article.publicationDate).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000;

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        {t('common.loading')}...
      </div>
    );
  }

  return (
    <div>
      {/* Access upgrade banner */}
      {!hasFullAccess && (
        <div className="lg:mb-12 mb-4 rounded-lg lg:p-2">
          <p className="text-[#49ABDB] font-medium mb-2">
            {t('pubmed.limitedAccess')}
          </p>
          <a
            href="/signup"
            className="inline-block bg-[#49ABDB] text-white px-6 py-2 rounded-full font-medium hover:bg-[#3891c0] transition-colors"
          >
            {t('auth.getStarted')}
          </a>
        </div>
      )}
  
      {/* Main content */}
      {latestRubricsData.length === 0 && topCitedRubricsData.length === 0 ? (
        // Featured layout (fallback)
        featuredArticle && (
          <FeaturedLayout
            featuredArticle={featuredArticle}
            sideArticles={sideArticles}
            gridArticles={gridArticles}
            onRubricClick={handleRubricClick}
            onReadStudy={handleReadFullStudy}
            getTranslation={getTranslation}
            getImageUrl={getImageUrl}
            isRecent={isRecent}
            t={t}
          />
        )
      ) : (
        <>
          {/* Latest Studies */}
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-6">
            {t('pubmed.latestStudies')}
          </h2>
          {latestRubricsData.map(({ rubricId, rubricName, articles, currentPage, totalPages }) => (
            <div key={rubricId} className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{rubricName}</h3>
              <ArticleGrid
                articles={articles}
                onRubricClick={handleRubricClick}
                onReadStudy={handleReadFullStudy}
                getTranslation={getTranslation}
                getImageUrl={getImageUrl}
                isRecent={isRecent}
                t={t}
              />
              <div className="mt-4">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(rubricId, newPage, 'latest')}
                />
              </div>
            </div>
          ))}
  
          {/* Top Cited Studies */}
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-6 mt-8">
            {t('pubmed.topCitedStudies')}
          </h2>
          {topCitedRubricsData.map(({ rubricId, rubricName, articles, currentPage, totalPages }) => (
            <div key={rubricId} className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{rubricName}</h3>
              <ArticleGrid
                articles={articles}
                onRubricClick={handleRubricClick}
                onReadStudy={handleReadFullStudy}
                getTranslation={getTranslation}
                getImageUrl={getImageUrl}
                isRecent={isRecent}
                t={t}
              />
              <div className="mt-4">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(rubricId, newPage, 'top-cited')}
                />
              </div>
            </div>
          ))}
        </>
      )}
  
      {/* Austrian Articles Section */}
      {austrianArticles.length > 0 && (
        <div className="mt-16">
          <h2 className="text-2xl xl:text-4xl font-bold text-[#49ABDB] mb-8">
            {t('pubmed.austrianStudies')}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
            {austrianArticles.map((article) => (
              <div
                key={article.pmid}
                className="bg-[#cde1ee] p-4 relative min-h-[200px] xl:h-[290px] flex flex-col overflow-hidden"
              >
                <div className="flex flex-1">
                  <div className="flex-1">
                    <div className="px-2 py-1 rounded text-xs xl:text-base bg-[#EF3340] text-white inline-block">
                      {article.journal?.name || t('pubmed.austrianJournal')}
                    </div>
                    <AdaptiveTitle
                      text={getTranslation(article)?.translatedTitle || article.title}
                      baseClassName="h-24 mt-2"
                      maxFontSize={20}
                      minFontSize={14}
                    />
                    <div className="text-xs xl:text-sm text-[#111D4E] mt-2">
                      {article.authors?.slice(0, 2).join(', ')}
                      {article.authors?.length > 2 && ' et al.'}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleReadFullStudy(article.pmid)}
                  className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                  {t('pubmed.readAustrianStudy')}
                </button>
              </div>
            ))}
          </div>
          <PaginationControls
            currentPage={austrianPage}
            totalPages={austrianTotalPages}
            onPageChange={handleAustrianPageChange}
          />
        </div>
      )}
  
      {/* Upgrade Modal */}
      <UpgradeModal isOpen={isUpgradeModalOpen} onClose={handleUpgradeModalClose} />
    </div>
  );

};
export default PubMedDisplay;
