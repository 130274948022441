// components/StatusModal.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'success' | 'error';
}

const StatusModal: React.FC<StatusModalProps> = ({ isOpen, onClose, type }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
        <div 
          className="fixed inset-0 transition-opacity bg-black bg-opacity-40" 
          onClick={onClose}
        />

        <div className="relative z-50 inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="mt-2">
            <h3 className={`text-lg font-medium leading-6 ${
              type === 'success' ? 'text-green-600' : 'text-red-600'
            } mb-2`}>
              {type === 'success' 
                ? t('rubrics.saveSuccess') 
                : t('rubrics.saveError')}
            </h3>
            <p className="text-sm text-gray-500">
              {type === 'success' 
                ? t('rubrics.saveSuccessDescription')
                : t('rubrics.saveErrorDescription')}
            </p>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] rounded-md hover:opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={onClose}
            >
              {t('rubrics.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;