import React from 'react';
import { PubMedArticle } from '../types';
import AdaptiveTitle from './AdaptiveTitle';

interface PubMedCardProps {
    article: PubMedArticle;
    onRubricClick: (rubricId: number) => void;
    onReadStudy: (pmid: string) => void;
    getTranslation: (article: PubMedArticle) => any;
    getImageUrl: (name: string | undefined, size?: 'big' | 'small') => string;
    isRecent: (article: PubMedArticle) => boolean;
    t: (key: string) => string;
}

export const PubMedCard: React.FC<PubMedCardProps> = ({
    article,
    onRubricClick,
    onReadStudy,
    getTranslation,
    getImageUrl,
    isRecent,
    t
}) => {
    return (
        <div className="bg-[#cde1ee] p-4 relative h-[290px] flex flex-col overflow-hidden">
            {article.citationCount === 0 && isRecent(article) && (
                <div className="absolute top-0 right-0 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white text-xs font-bold py-1 px-6 transform origin-top-right rotate-45 translate-y-8 translate-x-4">
                    {t('pubmed.new')}
                </div>
            )}
            <div className="flex flex-1">
                <div className="flex-1">
                    <button 
                        onClick={() => article.topic?.rubric?.id && 
                            onRubricClick(article.topic.rubric.id)}
                        className="px-2 py-1 rounded text-xs xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                    >
                        {article.topic?.rubric?.name || ''}
                    </button>
                    <AdaptiveTitle 
                        text={getTranslation(article)?.translatedTitle || article.title}
                        baseClassName="h-24 mt-2"
                        maxFontSize={20}
                        minFontSize={14}
                    />
                    <p className="text-xs xl:text-base text-[#111D4E] mt-2 line-clamp-2">
                        {getTranslation(article)?.keywords?.join(' • ')}
                    </p>
                </div>
                <img
                    src={getImageUrl(article.topic?.rubric?.name, 'small')}
                    alt={article.topic?.rubric?.name || ''}
                    className="w-16 h-16 sm:w-20 sm:h-20 object-contain ml-4"
                />
            </div>
            <button
                onClick={() => onReadStudy(article.pmid)}
                className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
            >
                {t('pubmed.readOriginal')}
            </button>
        </div>
    );
};