import React from 'react';
import { useNavigate } from 'react-router-dom';

const PostSignup: React.FC = () => {
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    // Send user to the Profile page, Subscription tab
    // or directly to your existing subscription route
    // For instance, if Subscription is a separate route: navigate('/subscription');
    // If you handle it in Profile?tab=subscription:
    navigate('/profile?tab=subscription');
  };

  const handleContinueFree = () => {
    // They remain a free user, redirect to home or login
    navigate('/login');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800 p-6">
      <h1 className="text-2xl font-bold mb-4">Welcome at HEARTPORT EXPERT!</h1>
      <p className="mb-8">Your account has been created successfully.</p>

      <div className="space-x-4">
        <button
          onClick={handleUpgradeClick}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Upgrade &amp; Subscribe
        </button>
        <button
          onClick={handleContinueFree}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
        >
          Continue as Free
        </button>
      </div>
    </div>
  );
};

export default PostSignup;
