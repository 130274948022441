import React from 'react';
import { PubMedArticle } from '../types';



interface PubMedSearchCardProps {
  article: PubMedArticle;
  currentLanguage: string;
}

const PubMedSearchCard: React.FC<PubMedSearchCardProps> = ({ article, currentLanguage }) => {
  const translation = article.translations.find(
    (t) => t.languageCode === currentLanguage
  ) || article.translations[0];

  return (
    <div className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow">
      <h3 className="font-bold text-[#49ABDB] mb-2">
        {translation ? translation.translatedTitle : article.title}
      </h3>
      <p className="text-sm text-gray-600 mb-2">
        {article.authors.slice(0, 3).join(', ')}
        {article.authors.length > 3 && ' et al.'}
      </p>
      <p className="text-xs text-gray-500">
        {article.journal} • {new Date(article.publicationDate).getFullYear()}
      </p>
      <div className="mt-2 text-xs">
        <span className="inline-block bg-blue-100 text-blue-800 px-2 py-1 rounded">
          Citations: {article.citationCount}
        </span>
      </div>
    </div>
  );
};

export default PubMedSearchCard;