// components/UpgradeModal.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
        {/* Background overlay */}
        <div 
          className="fixed inset-0 transition-opacity bg-black bg-opacity-40" 
          onClick={onClose}
        />

        {/* Modal panel */}
        <div className="relative z-50 inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="mt-2">
            <h3 className="text-lg font-medium leading-6 text-[#111D4E] mb-2">
              {t('pubmed.upgradeModalTitle')}
            </h3>
            <p className="text-sm text-gray-500">
              {t('pubmed.upgradeModalDescription')}
            </p>
          </div>

          <div className="mt-4 flex justify-end gap-2">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] rounded-md hover:opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={onClose}
            >
              {t('pubmed.gotIt')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;