import React from 'react';
import logo from '../assets/images/logo128.png';

const DemocratisingScience = () => {
  return (
    <div className="bg-transparent">
      <div className="flex flex-col space-y-0.5">
        <h1 className="text-3xl font-normal tracking-wider" style={{ color: '#49ABDB' }}>
          DEMOCRATIZING
        </h1>
        <div className="flex items-start gap-4">
          <h1 className="text-3xl font-normal tracking-wider" style={{ color: '#49ABDB' }}>
            SCIENCE
          </h1>
          <img 
            src={logo} 
            alt="Heart logo" 
            className="md:w-32 md:h-32 w-28 h-28 object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default DemocratisingScience;